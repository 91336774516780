<template>
  <div class="NewsDetails">
    <div class="banner">
      <Head />
      <img src="https://thcdn.gggamedownload.com/source/mobileAssets/ziye/z53.jpg" alt="">
      <div class="return" @click="clickReturn"><van-icon name="share-o" /></div>
    </div>
    <div class="body">
      <div class="title">
        <h3>{{ newsData.title }}</h3>
        <div class="date">{{ newsData.createTime | filterDate }}</div>
      </div>
      <div class="content" v-html="newsData.content" />
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Head from '../components/Head'
// import Footer from '@/components/MobileFloor'
import { reqGetContent } from '@/api/news'
export default {
  name: 'NewsDetails',
  filters: {
    filterDate(val) {
      if (val) {
        return val.substring(0, 10)
      }
      return val
    }
  },
  components: {
    Head
    // Footer
  },
  data() {
    return {
      newsData: {

      }
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  },
  methods: {
    async getData() {
      const id = this.$route.query.id
      this.top = this.$route.query.y
      const res = await reqGetContent(id)
      this.newsData = res.data
    },
    clickReturn() {
      // console.log(localStorage.getItem('routePath'))
      const path = localStorage.getItem('routePath')
      // this.$router.go(-1)
      this.$router.push({
        path,
        query: {
          y: this.top
        }
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    localStorage.setItem('routePath', from.path)
    next()
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
.content {
      width: 100%;
      img {
        width: 100%;
      }
      video {
        width: 100%;
      }
  margin-bottom: 70px;
  padding-top: 25px;

    }
}
.NewsDetails {
  padding-top: 59px;
  padding-bottom: 20px;

  .banner {
    position: relative;
    img {
      width: 100%;
    }
    .return {
      position: absolute;
      bottom: 8px;
      left: 20px;
      color: #fff;
      transform: rotateY(180deg);
      i {
        font-size: 24px;
      }
    }
  }
  .body {
    padding: 0 21px;
    .title {
      padding-top: 28px;
      padding-bottom: 19px;
      text-align: center;
      border-bottom: 1px solid #B2B2C2;
      h3 {
        font-size: 13px;
      }
      .date {
        margin-top: 12px;
        font-size: 12px;
        color: #B2B2C2;
      }
    }

  }
}
</style>
